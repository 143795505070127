import { Link } from "gatsby";
import Img from 'gatsby-image';
import React from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Col, Container, Row } from "../UI/Grid/index";
import PageHeader from "../UI/PageHeader/index";
import Sidebar from "./Blog-sidebar/index";
import { BlogPageWrapper } from "./blogGrid.style";

import { useSelector } from "react-redux";
import GaLink from "../UI/Ga-link";


const BlogPage = (props) => {


  const posts = useSelector(state => state.posts);
  const postsReverse = [...posts]
  const postMarkup = [...postsReverse.reverse()].map((post, key) => {

    return (
      <Col key={key} xs={12} sm={6}>
        <div className="post-block">

          <GaLink className="anchor" to={`blog/${post.node.slug}`} site="blog" category='post' value={post.node.slug}>
            {post.node.image && <Img fluid={post.node.image.childImageSharp.fluid} alt={post.node.image.name} />}
          </GaLink>

          <div className="post-content-block">
            <div className="post-meta-block">
              <Link className="anchor" to={`blog/${post.node.slug}`} site="blog" category='post' value={post.node.slug}>
                <span>Philipp</span>
              </Link>
              <span className="divider">/</span>
              <span>{post.node.date}</span>
            </div>
            <h2 className="post-title">
              <GaLink className="anchor" to={`blog/${post.node.slug}`} site="blog" category='post' value={post.node.slug}>
                {post.node.title}
              </GaLink>
            </h2>
            <p className="post-entry">
              {post.node.description}
            </p>

            <GaLink className="readmore-btn" to={`blog/${post.node.slug}`} site="blog" category='post' value={post.node.slug}>
              Mehr <IoIosArrowRoundForward />
            </GaLink>

          </div>
        </div>
      </Col>
    )
  });

  const categoryCapitalize = props.activeCategory ? capitalize(props.activeCategory) : 'Blog';

  return (
    <BlogPageWrapper>
      <PageHeader spaceTop={true} title={categoryCapitalize} />

      <div className="blog-page-wrapper">
        <Container>
          <Row>
            <Col xs={12} lg={8}>
              <div className="blog-grid-wrapper">
                <Row>
                  {postMarkup}
                </Row>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <Sidebar
                categories={[...props.categories]}
                activeCategory={props.activeCategory}
                tags={[...props.tags]}
                getParams={props.getParams}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </BlogPageWrapper>
  );
};

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default BlogPage;