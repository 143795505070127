const blogReducer = (state, action) => {
    switch (action.type) {
        case 'POSTS':                
            return {
                org : [...state.org],
                posts : [...action.posts]
            }
        default:
            return state;
    }
}

export default blogReducer;