import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import Button from "../../UI/Button";
import BlogSideBarWrapper from "./blogSidebar.style";

import { useSelector, useDispatch } from "react-redux";
import GaLink from "../../UI/Ga-link";

const SeideBar = (props) => {

  const [tags, setTags] = useState([...props.tags]);

  const orgPosts = useSelector(state => state.org);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.getParams && props.getParams.tag) {
      const tag = (props.getParams.tag).replace(" ","");
      const foundTag = tags.find(t => t.node.title.toLowerCase().replace(" ","") === tag);
      if(foundTag){
        foundTag.node.active = true;
        setNewTagsByTag(foundTag);
      }
    }
  }, [])

  const CategoriesMarkup = props.categories.map((category, key) =>
    <li key={key} className={category.node.title.toLowerCase() === props.activeCategory ? 'active' : ''}>
      <GaLink site="blog" category='category' site="blog" value={category.node.title.toLowerCase()} to={category.node.title.toLowerCase() === props.activeCategory ? `/blog` : `/blog/category/${category.node.title.toLowerCase()}`}>
        <span>{category.node.title}</span>
        {/* <em>( {category.node.count} )</em> */}
      </GaLink>
    </li>
  );



  const clickTag = (e, tag) => {
    e.preventDefault();
    setNewTagsByTag(tag);
  }


  const setNewTagsByTag = (tag) => {
    const tag_id = tag.strapiId;

    if (tag.active === true) {
      setTags([...tags.map(tag => {
        if (tag_id === tag.node.strapiId) {
          tag.node.active = false;
        }
        return tag;
      })]);
    
      if (!tags.find(tag => tag.node.active)) {
        dispatch({
          type: 'POSTS',
          posts: [...orgPosts]
        });
        return;
      }
      dispatch({
        type: 'POSTS',
        posts: [...setNewPostByTagFilter()]
      });
      return;
    }

    setTags([...tags.map(tag => {
      if (tag.node.strapiId === tag_id) {
        tag.node.active = true;
      }
      return tag;
    })]);

    dispatch({
      type: 'POSTS',
      posts: [...setNewPostByTagFilter()]
    });
  }

  const setNewPostByTagFilter = () => {
    const newPosts = [];
    [...orgPosts].map(post => {
      [...tags].map(o_tag => {
        if (!o_tag.node.active) {
          return;
        };
        const tag_id = o_tag.node.strapiId
        if (post.node.tags.length > 0) {
          post.node.tags.map(tag => {
            if (tag.id === tag_id) {
              if (!newPosts.find(newPost => newPost.node.strapiId === post.node.strapiId)) {
                newPosts.push(post);
                return;
              }
            }
          })
        }
      })
    });
    return newPosts;
  }

  const tagsMarkup = tags.map((tag, key) =>
    <li key={key}>
      <Link
        className={tag.node.active ? 'active' : ''}
        to="#"
        onClick={(e) => clickTag(e, tag.node)}>{tag.node.title}
      </Link>
    </li>
  );

  let tagsContainer = tags.length > 0 && (
    <div className="sidebar-widgets tags">
      <div className="widgets-title">
        <h3>Tags</h3>
      </div>
      <ul>
        {tagsMarkup}
      </ul>
    </div>
  )

  return (
    <BlogSideBarWrapper>
      {/* <div className="sidebar-widgets">
        <div className="search-box">
          <input type="text" placeholder="Search here..." />
          <Button>
            <FaSearch />
          </Button>
        </div>
      </div> */}

      <div className="sidebar-widgets catagorie">
        <div className="widgets-title">
          <h3>Kategorien</h3>
        </div>
        <ul>
          {CategoriesMarkup}
        </ul>
      </div>
      {tagsContainer}
    </BlogSideBarWrapper>
  );
};


export default SeideBar;