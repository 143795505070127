import styled from 'styled-components';


const PageHeaderWrapper = styled.div`
        position: relative;
        overflow: hidden;
        z-index:1;

        .page-header {
            text-align: center;
            padding: 10px 0px 35px;
            position: relative;
            z-index: 99;
            h1{
                font-size: 36px;
                line-height: 48px;
            }

        .breadcrumbs {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;  
            a {
                margin: 0 5px 0px 0;
                color: inherit;

                &:hover{
                    opacity: .8;
                }
            }             
            h4 {
                margin:0 7px;
                opacity: .7;
            }
            &.post{
                @media(max-width:662px){
                    h4{
                        margin:10px 0;
                    }
                    display: inline-block;
                    justify-content: center;
                    align-items: center;
                }
            }
            &.page {
                a {
                   margin:0;
                }
                span {
                    margin: 0 3px 0 3px;
                }            
            }


        } 
    }
    @media only screen and (max-width: 480px) {
       .page-header{
            padding: 10px 0px 10px;
            h1{
                font-size: 30px;
                line-height: 44px;
            }
       }
    }
`

export default PageHeaderWrapper;