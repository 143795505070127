import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/UI/Layout/layout"
import SEO from "../components/seo"
import BlogPage from "../components/Blog-grid/index"
import { createStore } from 'redux';
import { Provider } from "react-redux";
import blogReducer from "../redux/reducers/blogReducer"
import queryString from 'query-string'

const BlogGridPage = (data) => {

  const getParams = data.location.search ? queryString.parse(data.location.search) : null


  const blogData = data.data;

  const allPosts = blogData.allPosts.edges;
  const posts = blogData.posts.edges;
  const tags = blogData.tags.edges.map(tag => {
    tag.node.active = false;
    return tag;
  });
  const activeCategory = Object.keys(data.pageContext).length > 0 ? data.pageContext.title.toLowerCase() : '';

  const categories = blogData.categories.edges.filter(category => {
    let i = 0;
    let catId = category.node.strapiId;
    allPosts.map(post => {
      post.node.categories.map(cat => {
        if (cat.id === catId) {
          i++;
        }
      })
    })
    category.node.count = i
    if (i > 0) {
      return category;
    }
  })


  const store = createStore(blogReducer, {
    posts: posts,
    org: posts
  });

  return (
    <Provider store={store}>
      <Layout>
        <SEO
          metaTitle="Philipps Blog - alles rund um App / Web Entwicklung"
          metaDescription="Backend, Frontend, Hybrid, Cross-Platform oder JAMstack - Im meinem Blog wirst du fündig all diese Technologien und noch mehr" />
        <BlogPage
          posts={posts}
          categories={categories}
          tags={tags}
          activeCategory={activeCategory}
          getParams={getParams}
        />
      </Layout>
    </Provider>
  )

}
export default BlogGridPage




export const query = graphql`
  query($title: [String]) {
    posts: allStrapiArticle(filter: {categories: {elemMatch: {title: {in: $title}}}}) {
      edges {
        node {
          categories {
            id
            title
          }
          tags {
            id
          }
          content_short      
          image {
            childImageSharp {
              fluid(maxWidth:360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slug
          strapiId
          description: Meta_description
          title
          date: createdAt(formatString: "DD. MMMM YYYY")
        }
      }
    }
  categories: allStrapiProgrammingCategory {
    edges {
      node {
        strapiId
        title
        programming_languages {
          title
        }
      }
    }
  }
  tags: allStrapiProgrammingLanguage(filter: {programming_categories: {elemMatch: {title: {in: $title}}}}) {
    edges {
      node {
        strapiId
        title
        color
        background: background_color
      }
    }
  }
  allPosts: allStrapiArticle{
    edges {
      node {
        image {        
          name
          childImageSharp {
            fluid(maxWidth:500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        categories {
          id
          title
        }        
      }
    }
  }
}
`

