import React from "react";
import { Link } from 'gatsby';

import ParticleComponent from "../Particle";
import PageHeaderWrapper from "./pageHeader.style";

const PageHeader = ({ categories, title, author, date, spaceTop }) => {
  return (
    <PageHeaderWrapper>
      <ParticleComponent />
      <div style={{ marginTop: spaceTop ? '200px' : '10px' }} className="page-header">
        <h1>{title}</h1>
        {categories && (
          <div className="breadcrumbs post">
            <h4>In:</h4>
            {categories.map((category, index) => (
              <Link to={`blog/category/${category.title.toLowerCase()}`} key={index}> {category.title}{index !== categories.length - 1 ? ',' : ''}</Link>
            ))}
            {author && (
              <>
                <h4>By:</h4> <span>{author.name}</span>
              </>
            )}
            {date && (
              <>
                <h4>At:</h4> <span>{date}</span>
              </>
            )}
          </div>
        )}
        {!categories && title && (
          <div className="breadcrumbs page">
            <>
              <Link to="/"><span> Home ></span></Link>
              <Link to="/blog"><span> Blog</span></Link>
              <Link to={`/blog/category/${title.toLowerCase()}`}><span>{title && title !== 'Blog' ? `> ${title}` : ''}</span></Link>
            </>
          </div>
        )}
      </div>
    </PageHeaderWrapper>
  );
};
export default PageHeader;
